<template>
  <div class="borde">
    <template v-if="elemento.tipo === undefined">
      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            color="green darken-2"
            v-bind="attrs"
            v-on="on"
            @click="agregarNodos(elemento)"
          >
            mdi-sitemap
          </v-icon>
        </template>
        <span>Agregar Agrupación AND/OR</span>
      </v-tooltip>

      <v-tooltip top>
        <template v-slot:activator="{ on, attrs }">
          <v-icon
            small
            color="green darken-2"
            v-bind="attrs"
            v-on="on"
            @click="elemento.tipo = 'regla'"
          >
            mdi-source-repository
          </v-icon>
        </template>
        <span>Agregar Regla</span>
      </v-tooltip>
    </template>

    <template v-if="elemento.tipo === 'regla'">
      <v-chip
        class="ma-2"
        small
        color="#eaeaea"
        text-color="blue"
        @click="cambiarCumple(elemento)"
        style="float: left"
      >
        {{ elemento.cumple }}
      </v-chip>

      <div v-if="!reglas.length" class="spinner-border" role="status"></div>
      <v-select
        v-if="reglas.length"
        v-model="elemento.regla"
        :items="reglas"
        :item-text="'nombre'"
        :item-value="'id'"
        :rules="required"
        :title="elemento.regla?elemento.regla.nombre:''"
        return-object
        style="float: left; width: 100px; height: 40px"
        dense
      ></v-select>
    </template>

    <span v-if="elemento.tipo === 'opl'">
      <span class="sup">(</span>
      <template v-for="(item, index) in elemento.nodos" style="float: left">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-chip
              class="ma-2"
              small
              color="#eaeaea"
              text-color="red"
              v-bind="attrs"
              v-on="on"
              v-if="index !== 0"
              @click="cambiarOperador(elemento, index)"
            >
              {{ elemento.operadores[index - 1] }}
            </v-chip>
          </template>
          <span>Clic para cambiar</span>
        </v-tooltip>

        <nodo :elemento="item" :reglas="reglas"></nodo>
      </template>
      <span class="sup">)</span>
    </span>
    <!-- <v-tooltip top v-if="elemento.tipo !== undefined">
      <template v-slot:activator="{ on, attrs }">
        <button @click="reset(elemento)" v-bind="attrs" v-on="on" class="sup">
          <sup>-</sup>
        </button>
      </template>
      <span>Reset</span>
    </v-tooltip> -->
    <button
      v-if="elemento.tipo !== undefined"
      @click="reset(elemento)"
      class="sup"
    >
      <sup>x</sup>
    </button>
    <button
      style="margin-left:5px"
      v-if="elemento.tipo == 'opl'"
      @click="agregarOperador(elemento)"
      class="sup"
    >
      <sup>+</sup>
    </button>
  </div>
</template>

<script>
import { mapState } from "vuex";
import NodoOpl from "./NodoOpl.js";

export default {
  name: "nodo",
  props: {
    elemento: {
      type: Object,
      default: () => new NodoOpl(),
    },
    reglas: {
      type: Array,
      default: () => new Array(),
    },
  },
  data() {
    return {
      dialog: false,
      valid: false,
      rules: [
        (value) => !!value || "Requerido",
        (value) => (value && value.length >= 3) || "Mínimo 3 caracteres",
      ],
      required: [(value) => !!value || "Requerido"],
    };
  },
  components: {},
  computed: {
    ...mapState(["permissions"]),
  },
  watch: {},
  mounted() {},
  methods: {
    agregarNodos(nodo) {
      nodo.tipo = "opl";
      nodo.operadores.push("AND");
      nodo.nodos.push(new NodoOpl());
      nodo.nodos.push(new NodoOpl());
    },
    agregarOperador(nodo) {
      nodo.operadores.push("AND");
      nodo.nodos.push(new NodoOpl());
    },
    agregarRegla() {
      console.log("Elemento...");
      console.log(this.elemento);
      this.dialog = false;

      this.elemento.tipo = "regla";
      this.elemento.regla = undefined;
    },
    cambiarOperador(elemento, index) {
      if (elemento.operadores[index - 1] === "AND") {
        elemento.operadores[index - 1] = "OR";
      } else {
        elemento.operadores[index - 1] = "AND";
      }
      this.$forceUpdate();
    },
    cambiarCumple(elemento) {
      if (elemento.cumple === "IS") {
        elemento.cumple = "IS NOT";
      } else {
        elemento.cumple = "IS";
      }
      this.$forceUpdate();
    },
    reset(elemento) {
      elemento.tipo = undefined;
      elemento.operadores = [];
      elemento.nodos = [];

      elemento.regla = undefined;
      elemento.cumple = "IS";
    }
  },
};
</script>

<style scoped>
.borde {
  border: 1px solid #eaeaea;
  display: inline-block;
  margin: 5px;
}

.sup {
  font-size: 24px;
  font-weight: bold;
  color: #999;
}
</style>
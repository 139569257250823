var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"borde"},[(_vm.elemento.tipo === undefined)?[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"green darken-2"},on:{"click":function($event){return _vm.agregarNodos(_vm.elemento)}}},'v-icon',attrs,false),on),[_vm._v(" mdi-sitemap ")])]}}],null,false,385173898)},[_c('span',[_vm._v("Agregar Agrupación AND/OR")])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"small":"","color":"green darken-2"},on:{"click":function($event){_vm.elemento.tipo = 'regla'}}},'v-icon',attrs,false),on),[_vm._v(" mdi-source-repository ")])]}}],null,false,2208327380)},[_c('span',[_vm._v("Agregar Regla")])])]:_vm._e(),(_vm.elemento.tipo === 'regla')?[_c('v-chip',{staticClass:"ma-2",staticStyle:{"float":"left"},attrs:{"small":"","color":"#eaeaea","text-color":"blue"},on:{"click":function($event){return _vm.cambiarCumple(_vm.elemento)}}},[_vm._v(" "+_vm._s(_vm.elemento.cumple)+" ")]),(!_vm.reglas.length)?_c('div',{staticClass:"spinner-border",attrs:{"role":"status"}}):_vm._e(),(_vm.reglas.length)?_c('v-select',{staticStyle:{"float":"left","width":"100px","height":"40px"},attrs:{"items":_vm.reglas,"item-text":'nombre',"item-value":'id',"rules":_vm.required,"title":_vm.elemento.regla?_vm.elemento.regla.nombre:'',"return-object":"","dense":""},model:{value:(_vm.elemento.regla),callback:function ($$v) {_vm.$set(_vm.elemento, "regla", $$v)},expression:"elemento.regla"}}):_vm._e()]:_vm._e(),(_vm.elemento.tipo === 'opl')?_c('span',[_c('span',{staticClass:"sup"},[_vm._v("(")]),_vm._l((_vm.elemento.nodos),function(item,index){return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(index !== 0)?_c('v-chip',_vm._g(_vm._b({staticClass:"ma-2",attrs:{"small":"","color":"#eaeaea","text-color":"red"},on:{"click":function($event){return _vm.cambiarOperador(_vm.elemento, index)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.elemento.operadores[index - 1])+" ")]):_vm._e()]}}],null,true)},[_c('span',[_vm._v("Clic para cambiar")])]),_c('nodo',{attrs:{"elemento":item,"reglas":_vm.reglas}})]}),_c('span',{staticClass:"sup"},[_vm._v(")")])],2):_vm._e(),(_vm.elemento.tipo !== undefined)?_c('button',{staticClass:"sup",on:{"click":function($event){return _vm.reset(_vm.elemento)}}},[_c('sup',[_vm._v("x")])]):_vm._e(),(_vm.elemento.tipo == 'opl')?_c('button',{staticClass:"sup",staticStyle:{"margin-left":"5px"},on:{"click":function($event){return _vm.agregarOperador(_vm.elemento)}}},[_c('sup',[_vm._v("+")])]):_vm._e()],2)}
var staticRenderFns = []

export { render, staticRenderFns }